<template>
  <v-app>
    <v-col cols="11" class="mx-auto">
      <div class="jumbotron py-5">
        <v-row>
          <v-col cols="6" class="mr-auto">
            <p class="display-1">Manage Claim Batch</p>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn
              medium
              color="green darken-4 white--text"
              class="text-uppercase"
              :to="{ name: 'create-batch' }"
            >
              <small>Create New Batch</small>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="px-4 pb-6 mb-12 mt-12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class>
                <th>S/No</th>
                <th>Batch Name</th>
                <th>Date Received</th>
                <th>Provider</th>
                <th>Claims</th>
                <th>Total Claimed(NGN)</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="(batch, index) in batches" :key="batch.id">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ batch.name }}</td>
                <td>{{ batch.date_created }}</td>
                <td>{{ batch.created_by }}</td>
                <td>{{ batch.claims.length }}</td>
                <td>{{ totalClaimed(batch.claims) }}</td>
                <td class="text-right">
                  <v-btn
                    small
                    :to="{
                      name: 'view-batch-claim-list',
                      params: { batch_id: batch.id },
                    }"
                    color="black darken-4 white--text"
                  >
                    <small class="font-weight-bold">View Claim</small>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-col>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ViewBatches",
  computed: {
    ...mapGetters({ batches: "claimModule/batches" }),
  },
  methods: {
    totalClaimed(value) {
      //computes the total amount claimed based on claims present within a batch
      if (value.length > 1) {
        return value.reduce(
          (current, next) => current.amount_claimed + next.amount_claimed
        );
      } else if (value.length === 1) {
        return value[0].amount_claimed;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
</style>
